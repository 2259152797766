import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./UserTable.css";
import { Table, Avatar, Button, Space, Input } from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import {
  getPage,
  createOne,
  updateOne,
  deleteOne,
  getAll,
  resetPassword,
} from "../../features/userSlice";

import {
  convertToReadableDateString,
  getQuery,
  getQueryRawData,
} from "../../utils/functions";

const UserTable = ({ rowSelection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 3,
      showSizeChanger: true,
      pageSizeOptions: ["3", "5", "10", "20"],
    },
    filters: {
      name: "",
    },
    sorter: {
      plan: null,
      paid_date: null,
      credit: null,
    },
  });

  useEffect(() => {
    if (userData.status === "idle") {
      dispatch(getAll());
      // dispatch(getPage(getQueryRawData(tableParams)));
    }
  }, [userData.status]);

  useEffect(() => {
    dispatch(getAll());
    // dispatch(getPage(getQueryRawData(tableParams)));
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => ({
      ...prevState,
      pagination,
    }));
    if (sorter) {
      let flag1 = null;
      let flag2 = null;
      let flag3 = null;
      if (sorter.field === "plan") {
        if (sorter.order === "ascend") {
          flag1 = "asc";
        }
        if (sorter.order === "descend") {
          flag1 = "desc";
        }
      }
      if (sorter.field === "paid_date") {
        if (sorter.order === "ascend") {
          flag2 = "asc";
        }
        if (sorter.order === "descend") {
          flag2 = "desc";
        }
      }
      if (sorter.field === "credit") {
        if (sorter.order === "ascend") {
          flag3 = "asc";
        }
        if (sorter.order === "descend") {
          flag3 = "desc";
        }
      }
      setTableParams((prevState) => ({
        ...prevState,
        sorter: {
          plan: flag1,
          paid_date: flag2,
          credit: flag3,
        },
      }));
    }
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    // confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    setTableParams((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [dataIndex]: selectedKeys[0],
      },
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Clear
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const resetPasswordHandler = (email) => {
    dispatch(resetPassword({ email }));
  };

  const columns = [
    {
      title: "User ID and email",
      dataIndex: "name",
      key: "name",
      width: "30%",
      textWrap: "word-break",
      ellipsis: true,
      ...getColumnSearchProps("name"),
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={record?.avatar} />
            <span style={{ marginLeft: 8 }}>
              {text}
              <br />
              {record.email}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "blackListed",
      key: "blackListed",
      width: "10%",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginLeft: 8 }}>
              {text}
              {record.blackListed ? "Banned" : "Actived"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      width: "10%",
      textWrap: "word-break",
      ellipsis: true,
      sorter: true,
    },
    {
      title: "Expired Date",
      dataIndex: "expired_date",
      key: "expired_date",
      width: "16%",
      textWrap: "word-break",
      ellipsis: true,
      sorter: true,
      render: (text, record, index) => {
        if (!text) {
          return "";
        }
        return convertToReadableDateString(text);
      },
    },
    {
      title: "Actions",
      textWrap: "word-break",
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              className="usertable-manage-button"
              onClick={() => navigate(`/home/user/${record.id}`)}
            >
              Manage Plan
            </Button>
            <Button
              className="usertable-reset-password-button"
              onClick={() => {
                resetPasswordHandler(record.email);
              }}
            >
              Reset Password
            </Button>
            <Button
              className="usertable-email-button"
              onClick={() => navigate(`/home/user/email?email=${record.email}`)}
            >
              Direct Email
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className="user-table"
      columns={columns}
      rowKey={(record) => record.id}
      dataSource={userData.data}
      pagination={{
        ...tableParams.pagination,
        total: userData.data.totalResults,
        showTotal: (total) => `All ${total} users`,
      }}
      loading={userData.loading}
      onChange={handleTableChange}
      rowSelection={rowSelection}
    />
  );
};

export default UserTable;
