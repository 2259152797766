import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

import { Form, Input, Button, Card } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";

import "./Login.css";
import Loading from "./../../components/Loading";
import { successNotification } from "../../components/Notification";

const Login = () => {
  const navigate = useNavigate();
  const { login, user, loading } = useUserContext();

  const [userInfo, setUserInfo] = useState({ email: "", password: "" });

  const handleLogin = () => {
    login(userInfo.email, userInfo.password);
  };

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
  }, [user, navigate]);

  return (
    <div
      style={{
        backgroundColor: "#f0f2f5 !important",
        margin: "0",
        padding: "0",
        fontFamily: "'Roboto', sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card title="Login" className="login-card">
        <Form onFinish={handleLogin} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            // rules={[{ required: true, message: 'Please input your email!' }]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
              value={userInfo.email}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  email: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            // rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              value={userInfo.password}
              onChange={(e) =>
                setUserInfo({
                  ...userInfo,
                  password: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={loading}
            >
              {loading ? <Loading /> : "Log in"}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
