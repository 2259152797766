import React from "react";
import "./Loading.css"; // Import the CSS file for styles

const Loading = ({ small }) => {
  return (
    <div className="loading-container">
      <div className={small ? "spinner small" : "spinner"}>
        <svg
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 50 50"
          xmlSpace="preserve"
        >
          <path
            fill="currentColor"
            d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615s14.615,6.543,14.615,14.615H43.935z"
          />
        </svg>
      </div>
    </div>
  );
};

export default Loading;
