import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router";
import {
  Image as ImageIcon,
  Group as GroupIcon,
  CardMembership as CardMembershipIcon,
  AccountCircle as AccountCircleIcon,
  CloudUpload as CloudUploadIcon,
  Logout,
  DataUsageRounded,
  Upload,
} from "@mui/icons-material";
import "./Tab.css";

import useCurrentPathAndQuery from "../../hooks/useCurrentPathAndQuery";
import { useUserContext } from "../../contexts/UserContext";
import { successNotification } from "../../components/Notification";

const MyTab = () => {
  const currentUrl = useCurrentPathAndQuery();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(0);

  const fileRef = useRef(0);
  const logoRef = useRef(0);

  const { logout, updateInfo, user } = useUserContext();

  useEffect(() => {
    if (currentUrl.indexOf("user") > 0) {
      return setSelected(0);
    }
    if (currentUrl.indexOf("image") > 0) {
      return setSelected(1);
    }
    if (currentUrl.indexOf("membership") > 0) {
      return setSelected(2);
    }
    if (currentUrl.indexOf("account") > 0) {
      return setSelected(3);
    }
    setSelected(0);
  }, [currentUrl]);

  useEffect(() => {
    if (user && user.logo) {
      debugger
      loadLogoIcon(user.logo);
    }
  }, []);

  const handleClick = (clickedButton) => {
    switch (clickedButton) {
      case "user":
        navigate("/home/user");
        break;
      case "image":
        navigate("/home/image");
        break;
      case "membership":
        navigate("/home/membership");
        break;
      case "account":
        navigate("/home/account");
        break;
      default:
        navigate("/home/user");
        break;
    }
  };

  const getClassName = (id) => {
    if (selected === id) {
      return "tab-button  selected";
    } else {
      return "tab-button";
    }
  };

  const loadLogoIcon = (svg) => {
    debugger
    logoRef.current.innerHTML = svg;
    const children = logoRef.current.children[0];
    children.style.width = "30px";
    children.style.height = "30px";
  };

  const onFileChange = (e) => {
    const file = e.currentTarget.files[0];

    const reader = new FileReader();
    reader.onload = async (e) => {
      const context = e.currentTarget.result;
      if (context.length) {
        const result = context.substr(context.indexOf("<svg "));
        loadLogoIcon(result);

        await updateInfo({
          logo: result,
        });

        successNotification("Successfully completed!");
      }
    };

    if (!file) {
      return;
    }

    reader.readAsText(file);
  };

  const onFileClick = () => {
    fileRef.current.click();
  };

  return (
    <div className="tab-wrapper">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 30px",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "40px",
            alignItems: "center",
            width: "100%",
            overflowY: "auto",
            minWidth: "800px",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={<GroupIcon />}
            className={getClassName(0)}
            onClick={() => handleClick("user")}
          >
            Users
          </Button>
          <div
            style={{
              width: "100%",
              display: "flex",
              overflow: "hidden",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={onFileClick}
              style={{
                border: "none",
                width: "100%",
                marginTop: 5,
                display: "flex",  
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <b>LOGO</b>
              <Upload />&nbsp;&nbsp;&nbsp;&nbsp;
              <span ref={logoRef}>
                <DataUsageRounded />
              </span>
              <input
                ref={fileRef}
                type="file"
                onChange={onFileChange}
                accept="image/svg+xml"
                style={{
                  display: "none",
                }}
              />
            </Button>
            <Button
              type="text"
              icon={<Logout />}
              className={getClassName(1)}
              onClick={logout}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTab;
