import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {  useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Avatar, Button, Chip, FormControl, Select, MenuItem, InputLabel, Grid } from '@mui/material';
import { PauseCircleFilled, RemoveCircleOutline, PlayCircleFilled, SettingsInputAntennaTwoTone } from '@mui/icons-material';
import { DatePicker } from 'antd';
import { KeyboardReturn } from '@mui/icons-material';
import { getOne, updateOne } from '../../../../features/userSlice';
import Loading from '../../../../components/Loading';
import RemoveModal from '../../../../components/user/manage/RemoveModal';
import PauseModal from '../../../../components/user/manage/PauseModal';
import { getFormattedDay } from '../../../../utils/functions';
import "./Manage.css"
import { formatSortData } from './../../../../utils/functions';

const Manage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [membershipPlan, setMembershipPlan] = useState('');
  const [isRemoveOpen, setRemoveOpen] = useState(false);
  const [isPauseOpen, setPauseOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [expiredDate, setExpiredDate] = useState(dayjs(getFormattedDay(1), 'DD/MM/YYYY'));
  const [expiredDateError, setExpiredDateError] = useState(false);

  useEffect(() => {
    dispatch(getOne(id));
  }, [id]);

  const handleBack = () => {
    navigate("/home/user");
  }

  const handlePlanChange = (event) => {
    setMembershipPlan(event.target.value);
  };

  const plans = ["Free", "Basic", "Pro"];

  if(userData.loading) {
    return (
      <div  className="user-manage-section">
        <Loading/>
      </div>
    )
  }

  const handleNext = () => {
    setStep(1);
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const handleChangeDate = (date, dateString) => {
    setExpiredDate(date);
    if(!date) {
      setExpiredDateError(true);
    }
  }

  const handleConfirm = () => {
    if(!expiredDate) {
      return setExpiredDate(true);
    }
    const data={
      id: id,
      expired_date: expiredDate.$d,
      plan: membershipPlan
    }
    dispatch(updateOne(data));
  }

  return (
    <div  className="user-manage-section">
      <Box sx={{ maxWidth: 1080, margin: 'auto' }}>
        <Typography variant="h4" gutterBottom component="div" className="user-manage-title">
          Membership plan manager 
          <KeyboardReturn className= "user-manage-return-button" onClick={() => handleBack()} />
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div">
          Assign new membership or manually change when membership starts and expires for current member
        </Typography>

        <Card variant="outlined" sx={{ mb: 4, backgroundColor:  '#ededed', borderRadius: '20px'}}>
          <CardContent>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Avatar src= {userData.user?.picture} alt="Ellen Smith" />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">{userData.user?.name}</Typography>
                <Typography variant="body2">{userData.user?.email}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Current plan</Typography>
                <Typography variant="body2" color="primary">{userData.user?.plan} Membership</Typography>
              </Grid>
              <Grid item style={{marginLeft: '30px'}}>
                <Typography variant="body2">Status</Typography>
                <Typography variant="body1">
                  {userData.user?.blackListed ?
                    <Chip label="Pause" color="error" />:
                    <Chip label="Active" color="success" /> 
                  }
                </Typography>
              </Grid>
              <Grid item>
              {userData.user?.blackListed ?
                <Button variant="outlined" startIcon={<PlayCircleFilled />} onClick={() => setPauseOpen(true)}>Active</Button>:
                <Button variant="outlined" startIcon={<PauseCircleFilled />} onClick={() => setPauseOpen(true)}>Pause</Button> 
              }
                <Button variant="outlined" color="error" startIcon={<RemoveCircleOutline />} sx={{ ml: 1 }} onClick={() => setRemoveOpen(true)}>
                  Remove
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{ mb: 4, backgroundColor:  '#ededed', borderRadius: '20px'}}>
          <CardContent>
            <Typography variant="subtitle1" gutterBottom component="div" className="user-manage-subtitle">
              Assign New membership
            </Typography>
            <Typography variant="body2" gutterBottom component="div" className="user-manage-subdescription">
              Manually assign a new membership which will automatically become Active (Including paid membership)
            </Typography>
            {step === 0 &&
            <>
              <FormControl fullWidth margin="normal">
                <InputLabel id="membership-plan-label">Select one</InputLabel>
                <Select
                  labelId="membership-plan-label"
                  id="membership-plan-select"
                  value={membershipPlan}
                  label="Select Plan"
                  onChange={handlePlanChange}
                  style={{backgroundColor: "white"}}
                >
                  {plans.map((plan) => {
                    return <MenuItem value={plan}>{plan} Membership</MenuItem>
                  })}
                </Select>
              </FormControl>
              <Button
                className="user-manage-next-button"
                variant="contained"
                fullWidth
                disabled={!membershipPlan}
                onClick={() => handleNext()}
              >
                Next
              </Button>
            </>
            }
            {step === 1 && 
            <>
              <div style={{margin: '30px 10px 30px 10px', backgroundColor: 'white'}}>
                <Grid container spacing={2} style={{padding: '0px 10px 20px 10px'}}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body1" gutterBottom>
                      Membership
                    </Typography>
                    <Typography variant="body2">{membershipPlan} Plan</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{textAlign: 'center'}}>
                    <Typography variant="body1" gutterBottom>
                      Start on
                    </Typography>
                    <Typography variant="body2">{getFormattedDay(0)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4} style={{textAlign: 'end'}}>
                    <Typography variant="body1" gutterBottom>
                      Expires on
                    </Typography>
                    <Typography variant="body2">
                      <DatePicker 
                        variant="borderless" 
                        format='DD/MM/YYYY' 
                        disabledDate={disabledDate}
                        onChange={(date, dateString) => {handleChangeDate(date, dateString)}}
                        value={expiredDate}
                      />
                    </Typography>
                    {expiredDateError && <Typography variant="body3" gutterBottom style={{color: 'red'}}>
                      *Please input date.
                    </Typography>}
                  </Grid>
                </Grid>
              </div>
              <Button
                  className="user-manage-confirm-button"
                  variant="contained"
                  fullWidth
                  disabled={!membershipPlan}
                  onClick={() => handleConfirm()}
                >
                  Confirm
              </Button>
            </>
            }
           
          </CardContent>
        </Card>
      </Box>
      <RemoveModal open={isRemoveOpen} setOpen={setRemoveOpen} userId={userData.user?.id}/>
      <PauseModal open={isPauseOpen} setOpen={setPauseOpen} userId={userData.user?.id} status={userData.user?.blackListed}/>
    </div>
  );
};

export default Manage;