export const getQueryRawData = (tableParams) => {
  const searchData = formatSerachData(tableParams?.filters);
  const sortData = formatSortData(tableParams?.sorter);
  let result = {
    limit: tableParams.pagination.pageSize,
    page: tableParams.pagination.current,
  };
  if (sortData) {
    result = {
      ...result,
      sortBy: sortData,
    };
  }
  if (searchData.length) {
    for (let i = 0; i < searchData.length; i++) {
      result = {
        ...result,
        [searchData[i][0]]: searchData[i][1],
      };
    }
  }
  return result;
};

export const formatSerachData = (data) => {
  const result = Object.entries(data).filter(
    ([key, value]) => value !== null && value !== undefined && value !== ""
  );
  return result;
};

export const formatSortData = (data) => {
  return Object.entries(data)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => `${key}:${value}`)
    .join(",");
};

export function convertToReadableDateString(isoDateString) {
  const date = new Date(isoDateString);
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const getQuery = (data) => {
  // Sample data : {limit: 10, page: 2, sortBy: "name", email: "abc@gmail.com"}
  const keyValuePairs = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      );
    }
  }
  return "?" + keyValuePairs.join("&");
};

export const getFormattedDay = (months) => {
  const today = new Date();
  const date = new Date(today);
  date.setMonth(today.getMonth() + months);
  const formattedDate = date.toLocaleDateString("en-GB", {
    // 'en-GB' uses day/month/year format
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return formattedDate;
};
