import React, { useState } from "react";
import "./Account.css";
import EditProfile from "./edit-profile/EditProfile";
import { useUserContext } from "../../../contexts/UserContext";
import ChangePassword from "./change-password/ChangePassword";

const Account = () => {
  const { user } = useUserContext();

  const [editMode, setEditMode] = useState("");

  return (
    <div className="account-container">
      <div className="account-header">
        <h2>Account Preferences</h2>
        <hr />
      </div>
      <div className="account-studio">
        <div className="account-main">
          <div className="account-content">
            <div className="account-avatar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="72"
                height="72"
                viewBox="0 0 72 72"
                fill="none"
              >
                <circle cx="36" cy="36" r="36" fill="#8073FF" />
                <path
                  d="M49.4455 52.5C53.0655 52.5 56.1018 49.4208 54.4776 46.1857V46.1857C53.4725 44.1839 51.9993 42.3649 50.1421 40.8327C48.285 39.3006 46.0802 38.0852 43.6537 37.256C41.2272 36.4268 38.6264 36 36 36C33.3736 36 30.7728 36.4268 28.3463 37.256C25.9198 38.0852 23.715 39.3006 21.8579 40.8327C20.0007 42.3649 18.5275 44.1839 17.5224 46.1857V46.1857C15.8982 49.4208 18.9345 52.5 22.5545 52.5L36 52.5H49.4455Z"
                  fill="white"
                />
                <circle cx="36" cy="23.628" r="8.37209" fill="white" />
              </svg>
            </div>
            <div className="account-info">
              <div className={`account-label`}>
                <label>Email: </label>
                <strong>{user?.email}</strong>
              </div>
              <div className={`account-label account-name`}>
                <label>Name: </label>
                <span>
                  <b>{user?.name}</b>
                </span>
              </div>
            </div>
          </div>
          <div className="account-footer">
            <div
              className={`account-edit-information account-button ${
                editMode == "edit-profile" ? "selected" : ""
              }`}
              onClick={() => setEditMode("edit-profile")}
            >
              <span>Edit user information</span>
            </div>
            <div
              className={`account-change-password account-button ${
                editMode == "change-password" ? "selected" : ""
              }`}
              onClick={() => setEditMode("change-password")}
            >
              <span>Change password</span>
            </div>
          </div>
        </div>
        {editMode != "" ? (
          <div className="edit-studio">
            <div className="edit-times" onClick={() => setEditMode("")}>
              &times;
            </div>
            {editMode == "edit-profile" ? (
              <EditProfile />
            ) : editMode == "change-password" ? (
              <ChangePassword />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Account;
