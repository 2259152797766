import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../config/api";
import { getQuery } from "../utils/functions";
import {
  successNotification,
  errorNotification,
} from "../components/Notification";

export const getAll = createAsyncThunk("/user/get_all", async () => {
  const response = await api.get(`/admin/manage/user`);
  return response.data;
});

export const getPage = createAsyncThunk("/user/get_page", async (data) => {
  const response = await api.get(`/admin/manage/user${getQuery(data)}`);
  return response.data;
});

export const createOne = createAsyncThunk("/user/create_one", async (data) => {
  const response = await api.post(`/admin/manage/user`, {
    ...data,
    plan: "Free",
  });
  return response.data;
});

export const getOne = createAsyncThunk("/user/get_one", async (id) => {
  const response = await api.get(`/admin/manage/user/${id}`);
  return response.data;
});

export const updateOne = createAsyncThunk("/user/update_one", async (data) => {
  const id = data.id;
  delete data.id;
  const response = await api.patch(`/admin/manage/user/${id}`, data);
  return response.data;
});

export const deleteOne = createAsyncThunk("/user/delete_one", async (id) => {
  const response = await api.delete(`/admin/manage/user/${id}`);
  return response.data;
});

export const deleteMany = createAsyncThunk(
  "/user/delete_many",
  async (data) => {
    const response = await api.delete(`/admin/manage/user`, { data: data });
    return response.data;
  }
);

export const downloadUserData = createAsyncThunk(
  "/user/download_csv",
  async () => {
    const response = await api.get(`/admin/manage/user/download_csv`);
    return response.data;
  }
);

export const directEmail = createAsyncThunk("/user/email", async (data) => {
  const response = await api.post(`/admin/manage/user/email`, data);
  return response.data;
});

export const resetPassword = createAsyncThunk(
  "/user/reset_password",
  async (data) => {
    const response = await api.post(`/admin/manage/user/reset_password`, data);
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: [],
    user: null,
    status: "idle",
    error: null,
    loading: false,
    isDownloading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAll.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(getPage.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getPage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPage.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(getOne.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(getOne.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(getOne.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(createOne.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(createOne.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        successNotification("Succeed to add!");
      })
      .addCase(createOne.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(updateOne.pending, (state) => {
        state.status = "loading";
        state.loading = true;
      })
      .addCase(updateOne.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        state.user = action.payload;
        successNotification("Succeed to update!");
      })
      .addCase(updateOne.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(deleteOne.pending, (state) => {
        state.status = "loading";
        state.user = null;
        state.loading = true;
      })
      .addCase(deleteOne.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        successNotification("Succeed to delete!");
      })
      .addCase(deleteOne.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(deleteMany.pending, (state) => {
        state.status = "loading";
        state.user = null;
        state.loading = true;
      })
      .addCase(deleteMany.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        successNotification("Succeed to delete!");
      })
      .addCase(deleteMany.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(downloadUserData.pending, (state) => {
        state.status = "loading";
        state.isDownloading = true;
      })
      .addCase(downloadUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        const blob = new Blob([action.payload], { type: "text/csv" });
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        state.isDownloading = false;
      })
      .addCase(downloadUserData.rejected, (state, action) => {
        state.status = "failed";
        state.isDownloading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(directEmail.pending, (state) => {
        state.status = "loading";
        state.user = null;
        state.loading = true;
      })
      .addCase(directEmail.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        successNotification("Succeed to send!");
      })
      .addCase(directEmail.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = "loading";
        state.user = null;
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading = false;
        successNotification("Succeed to send!\nPlease check your inbox.");
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
        errorNotification(action.error.message);
      });
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
