import React, { useState } from "react";
import "./EditProfile.css";
import {
  successNotification,
  warningNotification,
} from "../../../../components/Notification";
import { useUserContext } from "../../../../contexts/UserContext";
import Loading from "../../../../components/Loading";

const EditProfile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  // const [plan, setPlan] = useState("one");

  const { user, updateInfo, loading } = useUserContext();

  const handleEditProfile = async () => {
    if (!name.length | !email.length) {
      warningNotification("Please fill out all inputs");
      return;
    }
    // API here

    const response = await updateInfo({
      name,
      email,
    });
    if (response !== "Bad") {
      setName("");
      setEmail("");
      successNotification("Please fill out all inputs");
    }
  };

  return (
    <div className="edit-container">
      <div className="edit-header">
        <h2>Edit Profile</h2>
      </div>
      <div className="edit-content">
        <div className="edit-name edit-label">
          <label>Change Name</label>
          <input
            className="edit-input"
            value={name}
            placeholder="New Username"
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </div>
        <div className="edit-email edit-label">
          <label>Change Email Address</label>
          <input
            className="edit-input"
            placeholder="New Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>
        {/* <div className="edit-plan edit-label">
          <label>Change Plan</label>
          <div className="button-group">
            <div
              className={`edit-button one-plan ${
                plan == "one" ? "selected" : ""
              }`}
              onClick={() => setPlan("one")}
            >
              One time plan
            </div>
            <div
              className={`edit-button ${plan == "recurring" ? "selected" : ""}`}
              onClick={() => setPlan("recurring")}
            >
              Recurring plan
            </div>
          </div>
        </div> */}
      </div>
      <div className="edit-footer">
        <div
          className={`edit-button ${loading ? "disabled" : ""}`}
          onClick={handleEditProfile}
        >
          {loading ? <Loading small /> : "Confirm"}
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
