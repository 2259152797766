import './App.css';
import { Provider } from "react-redux";
import  UserProvider from './contexts/UserContext';
import store from "./store";
import Router from "./router";

export default function App() {
  return (
    <Provider store={store}>
      <UserProvider>
        <Router />
      </UserProvider>
    </Provider>
  );
}
