export const headerStyle = {
  textAlign: 'center',
  color: '#000000',
  height: 70,
  margin: '20px 20px 5px 20px',
  // paddingInline: 48,
  lineHeight: '64px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 1px 10px 2px #CCCCCC',
  borderRadius: '20px',
  padding: '5px'
};
export const contentStyle = {
  // textAlign: 'center',
  // minHeight: 120,
  // lineHeight: '100px',
  // color: '#000000',
  // backgroundColor: '#FFFFFF',
  // margin: '10px 20px 10px 10px',
  // boxShadow: '0px 1px 10px 2px #CCCCCC',
  // borderRadius: '20px'
};
export const layoutStyle = {
  overflow: 'hidden',
  width: '100%',
  maxWidth: '100vw',
  height: '100vh',
  minHeight: '600px',
  // margin: '20px 30px',
  background: 'rgba(240, 240, 240, 0.5)',
};

export const tabStyle = {
  scrollX: 'auto',
  height: '70px',
  borderRadius: '20px',
  backgroundColor: '#ffffff',
  boxShadow: '0px 1px 10px 2px #CCCCCC',
  margin: '10px 20px 10px 10px',
  padding: '0px'
}

export const mainStyle = {
  scrollY: 'auto',
  overflow: 'hidden',
  borderRadius: '20px',
  backgroundColor: '#ffffff',
  margin: '5px 20px 10px 10px',
  boxShadow: '0px 1px 10px 2px #CCCCCC',
}