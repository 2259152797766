import React from 'react';
import { Layout, Flex } from 'antd';
import { Route, Routes, Navigate } from "react-router-dom";
import { layoutStyle, siderStyle, headerStyle, contentStyle, mainStyle, tabStyle} from "./Style"
import MyHeader from '../../layouts/header/Header';
// import MySider from '../../layouts/sider/Sider';
import MyTab from '../../layouts/tab/Tab';

import Account from '../../layouts/main/account/Account'
import User from '../../layouts/main/user/User';
import AddUser from '../../layouts/main/user/add/AddUser';
import Manage from '../../layouts/main/user/manage/Manage';
import DirectEmail from '../../layouts/main/user/email/DirectEmail';

import "./Home.css";

const { Header,  Sider, Content } = Layout;

const Home = () => {
  return (
    <>
      <Layout style={layoutStyle}>
        <Layout>
          <Layout style={contentStyle}>
            <Header style={tabStyle}>
              <MyTab/>
            </Header>
            <Content style={mainStyle}>
              <Routes>
                <Route path="/" element={<Navigate replace to="/home/user" />} />
                <Route path="/user" element={<User/>}/>
                <Route path="/user/add" element={<AddUser/>}/>
                <Route path="/user/email" element={<DirectEmail/>}/>
                <Route path="/user/:id" element={<Manage/>}/>
                <Route path="/account" element={<Account/>}/>
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  )
}

export default Home;