import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'antd';
import { Button } from 'antd';
import { FilterOutlined, FileExcelOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import UserTable from '../../../components/user/UserTable';
import DeleteModal from '../../../components/user/DeleteModal';
import { downloadUserData, deleteMany } from './../../../features/userSlice';
import Loading from './../../../components/Loading';

import './User.css';

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const [isSelectAll, setSelectAll] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if(isSelectAll) {
      return selectAllRows();
    }
    if(selectedRowKeys.length === userData.data?.length) {
      return setSelectedRowKeys([]);
    }
  }, [isSelectAll])

  const onSelectChange = (newSelectedRowKeys) => {
    if(newSelectedRowKeys.length < userData.data?.length) {
      setSelectAll(false);
    }
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const selectAllRows = () => {
    const allRowKeys = userData.data.map(item => item.id);
    setSelectedRowKeys(allRowKeys);
  };

  const handleAdd = () => {
    navigate('/home/user/add');
  }

  const downloadCSV = () => {
    dispatch(downloadUserData());
  }

  const handleDeleteMany = () => {
    setDeleteModal(true);
  }

  return(
    <div className="user-section">
      <h2  className="user-title">
        Users List
      </h2>
      <div className="user-breakline"/>
      <div className="user-function-list">
        <Checkbox checked={isSelectAll} onChange={(e) => setSelectAll(e.target.checked)} className="user-all-select">Select All</Checkbox>
        <Button type="primary" icon={<PlusOutlined/>}  className="user-button" onClick={() => handleAdd()}>
          Add New Member
        </Button>
        <Button icon={<FileExcelOutlined />} className = "user-csv-button user-button" onClick={() => downloadCSV()} disabled={userData.isDownloading}>
          Export CSV
          {userData.isDownloading && <Loading/>}
        </Button>
        <Button  type="dashed" icon={<DeleteOutlined />} className = "user-delete-button user-button" style={{display: selectedRowKeys.length ? 'block' : 'none'}} onClick={() => handleDeleteMany()}>
          Delete
        </Button>
    {/* <Button icon={<FilterOutlined />}>
          Filter
        </Button> */}
      </div>
      <div className="user-table-wrapper">
        <UserTable rowSelection={rowSelection}/>
      </div>
      <DeleteModal open={isDeleteModal} setOpen={setDeleteModal} userIds={selectedRowKeys} setSelected = {setSelectedRowKeys} isSelectAll={isSelectAll}/>
    </div>
  )
}

export default User;