import axios from "axios";
import { serverURL } from "./config";
import Cookies from "js-cookie";

export const api = axios.create({
  baseURL: serverURL // Replace with your server URL
});

api.interceptors.request.use(
  (config) => {
    if (!config.url.endsWith('/login')) {
      const token = Cookies.get('token'); // Assuming the token is stored in localStorage
      if (token) {
        if (config.url.endsWith('/download')) {
          config.responseType = 'blob'
        } else {
          config.responseType = 'json'
        }
        config.headers['Authorization'] = `Bearer ${token}`; // Attach the token as a Bearer token in the Authorization header
      }
    }
    return config;
  },
  (error) => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      Cookies.remove('token');
      Cookies.remove('adminId');
      Cookies.remove('admin');
      return Promise.resolve({ data: 'Unauthorized access - redirecting to login' });
    }
    return Promise.reject(error);
  }
);