import React, { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  loginWithEmailAndPassword,
  logOut,
  updateUserInfo,
  fetchUserInfo,
} from "../services/userService";

const userContext = createContext();

export const useUserContext = () => useContext(userContext);

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const login = async (email, password) => {
    setLoading(true);
    const result = await loginWithEmailAndPassword(email, password);
    setLoading(false);
    if (result) {
      const user = {
        ...result.data.admin,
        token: result.data.tokens.refresh.token,
      };
      Cookies.set("token", user.token);
      Cookies.set("adminId", user.id);
      Cookies.set("admin", JSON.stringify(user));
      setCurrentUser(user);
    }
  };

  const logout = async () => {
    setLoading(true);
    const result = await logOut(currentUser?.token);
    setLoading(false);
    if (result) {
      setCurrentUser(null);
      Cookies.remove("token");
      Cookies.remove("adminId");
      Cookies.remove("admin");
    }
  };

  const updateInfo = async (data) => {
    setLoading(true);
    const result = await updateUserInfo(currentUser.id, data);
    setLoading(false);
    if (result) {
      if (result === "Bad") {
        setCurrentUser(null);
        Cookies.remove("adminId");
        Cookies.remove("token");
        Cookies.remove("admin");
      } else {
        if (result) {
          const user = {
            ...result,
            token: currentUser.token,
          };
          setCurrentUser(user);
          Cookies.set("adminId", user._id);
          Cookies.set("admin", JSON.stringify(user));
        }
        return result;
      }
    }
  };

  useEffect(() => {
    if (!Cookies.get("admin")) {
      setCurrentUser(null);
    } else {
      if (!currentUser && Cookies.get("admin")) {
        setCurrentUser(JSON.parse(Cookies.get("admin")));
      }
    }
  }, [Cookies.get("admin")]);

  const value = {
    user: currentUser,
    setUser: setCurrentUser,
    loading,
    setLoading,
    login,
    logout,
    updateInfo,
  };

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
};

export default UserProvider;
