import React, { useState } from "react";
import "./ChangePassword.css";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../../components/Notification";
import { useUserContext } from "../../../../contexts/UserContext";
import Loading from "../../../../components/Loading";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { updateInfo, loading } = useUserContext();

  const handleChangePassword = () => {
    if (
      !currentPassword.length ||
      !newPassword.length ||
      !confirmPassword.length
    ) {
      warningNotification("Please fill out all inputs!");
      return;
    }

    if (newPassword != confirmPassword) {
      errorNotification("Not matched password!");
      return;
    }

    // API here
    const response = updateInfo({
      password: newPassword,
    });

    if (response != "Bad") {
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      successNotification("Password is updated");
    }
  };

  return (
    <div className="change-container">
      <div className="change-header">
        <h2>Change Password</h2>
      </div>
      <div className="change-content">
        <div className="change-password change-label">
          <label>Current Password</label>
          <input
            className="change-input"
            value={currentPassword}
            type="password"
            placeholder="Current Password"
            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
          />
        </div>
        <div className="change-password change-label">
          <label>New Password</label>
          <input
            className="change-input"
            placeholder="Set New Password"
            value={newPassword}
            type="password"
            onChange={(e) => setNewPassword(e.currentTarget.value)}
          />
          <input
            className="change-input confirm-password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          />
        </div>
      </div>
      <div className="change-footer">
        <div
          className={`change-button ${loading ? "disabled" : ""}`}
          onClick={handleChangePassword}
        >
          {loading ? <Loading small /> : "Save Password"}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
