import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Form, Typography } from "antd";
import TextField from "@mui/material/TextField";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { KeyboardReturn } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { createOne } from "../../../../features/userSlice";
import Loading from "../../../../components/Loading";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./AddUser.css";

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [plan, setPlan] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePlanChange = (event) => {
    setPlan(event.target.value);
    form.setFieldsValue({ plan: event.target.value });
  };

  const plans = ["Free", "Basic", "Pro"];

  const handleSubmit = (values) => {
    dispatch(createOne({ ...values, role: "user" }));
  };
  const handleBack = () => {
    navigate("/home/user");
  };

  return (
    <div className="user-manage-section">
      <Box sx={{ maxWidth: 600, margin: "auto" }}>
        <Card
          variant="outlined"
          sx={{ mb: 4, backgroundColor: "#f9f9f9", borderRadius: "20px" }}
        >
          <CardHeader
            title="Create New User"
            className="adduser-title"
            action={
              <IconButton aria-label="settings" style={{ marginTop: "10px" }}>
                <KeyboardReturn onClick={() => handleBack()} />
              </IconButton>
            }
          />
          <hr />
          <CardContent>
            <Form
              form={form}
              name="add_user"
              onFinish={handleSubmit}
              className="adduser-form"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input the user's name!",
                      },
                    ]}
                    style={{ marginBottom: "35px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                  </Form.Item>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input the user's email!",
                      },
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                    style={{ marginBottom: "35px" }}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                  </Form.Item>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input the user's password!",
                      },
                      {
                        min: 8,
                        message: "Password must be at least 8 characters!",
                      },
                      {
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
                        message:
                          "Password must contain at least one number and one letter!",
                      },
                    ]}
                  >
                    <FormControl
                      sx={{ width: "100%", backgroundColor: "white" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Form.Item>
                </Grid>
                {/* <Grid item xs={6} sm={3}>
                  <Form.Item
                    name="credit"
                    rules={[
                      { required: true, message: "Please input the credit!" },
                      {
                        pattern: new RegExp(/^\d+$/),
                        message: "Credit must be a number!",
                      },
                    ]}
                  >
                    <TextField
                      id="outlined-basic"
                      label="Credit"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                    />
                  </Form.Item>
                </Grid> */}
              </Grid>

              {/* <Form.Item
                name="plan"
                rules={[
                  { required: true, message: "Please select the user's plan!" },
                ]}
                style={{ marginBottom: "35px" }}
              >
                <FormControl fullWidth margin="normal" style={{backgroundColor: 'white'}}>
                  <InputLabel id="membership-plan-label">
                    Select Plan
                  </InputLabel>
                  <Select
                    labelId="membership-plan-label"
                    id="membership-plan-select"
                    label="Select Plan"
                    onChange={handlePlanChange}
                  >
                    {plans.map((plan) => {
                      return (
                        <MenuItem value={plan}>{plan} Membership</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Form.Item> */}
              <Form.Item>
                <Button
                  startIcon={<AddIcon />}
                  type="submit"
                  className="adduser-submit-button"
                >
                  {userData.loading ? <Loading /> : "Add"}
                </Button>
              </Form.Item>
            </Form>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AddUser;
