import axios from "axios";
import { serverURL } from "../config/config";
import { errorNotification } from "../components/Notification";
import { api } from './../config/api';

export const loginWithEmailAndPassword = async (email, password) => {
  const res = await axios.post(`${serverURL}/admin/auth/login`, { email, password })
  .catch(error => {
    if (error.response) {
      console.error('Login failed: Invalid username or password.');
      errorNotification("Invalid email or password!");
    } else if (error.request) {
      console.error('Network error or no response received:', error.request);
      errorNotification("Network error!");
    } else {
      console.error('Error:', error.message);
      errorNotification(error.message);
    }
    return null;
  });
  return res;
}

export const logOut = async (refreshToken) => {
  const res = await axios.post(`${serverURL}/admin/auth/logout`, {refreshToken})
  .catch(error => {
    console.error('Logout Error:', error.message);
    errorNotification(error.message);
    return false;
  });
  return true;
}

export const fetchUserInfo = async (id) => {
  const res = await api.get(`${serverURL}/admin/manage/admin/${id}`)
  .catch(error => {
    errorNotification(error.message);
    return null;
  });
  return res;
}

export const updateUserInfo = async (id, data) => {
  const res = await api.patch(`${serverURL}/admin/manage/admin/${id}`, { ...data })
  .catch(error => {
    if (error.response) {
      if (error.response.status === 404) {
        console.error('You are not an admin!');
        errorNotification("Something went wrong!");
        return "Bad";
      } else {
        console.error('Server responded with status code:', error.response.status);
        errorNotification("Network error!");
      }
    } else if (error.request) {
      console.error('Network error or no response received:', error.request);
      errorNotification("Network error!");
    } else {
      console.error('Error:', error.message);
      errorNotification(error.message);
    }
    return null;
  });
  return res.data;
}