import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useUserContext } from '../contexts/UserContext';

const PrivateRoute = ({ children }) => {
  const { user } = useUserContext();
  return user !== null || Cookies.get('adminId') ?  children : <Navigate to="/" replace />;
  // return Cookies.get('admin') !== null ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
