import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { updateOne } from '../../../features/userSlice';

export default function PauseModal({open, setOpen, userId, status}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handlePause = () => {
    const data = {
      id: userId,
      blackListed: !status
    };
    dispatch(updateOne(data));
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {status ? "Are you really going to remove this user from blacklist?" : "Are you really going to add this user to blacklist?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            BlackListed users can't use service as long as they are on blacklists.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={handlePause}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}