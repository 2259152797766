import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button, TextField, Box, Card, CardContent, Typography, CardHeader } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { KeyboardReturn } from '@mui/icons-material';
import { Form } from 'antd';
import { directEmail } from '../../../../features/userSlice';
import Loading from '../../../../components/Loading';
import "./DirectEmail.css";

const DirectEmail = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user);

  const [formData, setFormData] = useState({
    to: email,
    subject: '',
    content: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(directEmail(formData));
  }

  const handleBack = () => {
    navigate('/home/user');
  }

  if(userData.loading) {
    return (
      <div  className="user-email-section">
        <Loading/>
      </div>
    )
  }

  return(
    <div  className="user-email-section">
      <Box sx={{ maxWidth: 600, margin: 'auto' }}>
        <Card variant="outlined" sx={{ mb: 4, backgroundColor:  '#f9f9f9', borderRadius: '20px'}}>
          <CardHeader 
            title="Send Email" 
            className="adduser-title"         
            action={
              <IconButton aria-label="settings" style={{marginTop: '10px'}}>
                <KeyboardReturn onClick={() => handleBack()}/>
              </IconButton>
            }/>
          <hr/>
          <CardContent>
            <Form layout="vertical">
              <Form.Item>
                <TextField
                  name="to"
                  value={formData.to}
                  onChange={handleChange}
                  id="outlined-multiline-static"
                  label="To"
                  fullWidth
                  disabled={true}
                  required
                  style={{backgroundColor: 'white'}}
                />
              </Form.Item>
              <Form.Item>
                <TextField
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Write your subject here"
                  id="outlined-multiline-static"
                  label="Subject"
                  fullWidth
                  required
                  style={{backgroundColor: 'white'}}
                />
              </Form.Item>
              <Form.Item>
                <TextField
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  placeholder="Write your message here"
                  multiline
                  id="outlined-multiline-static"
                  label="Message"
                  rows={4}
                  fullWidth
                  required
                  style={{backgroundColor: 'white'}}
                />
              </Form.Item>
              <Form.Item>
                <Button type="submit" variant="contained" color="primary" style={{float: 'right'}} onClick={handleSubmit}>
                  Send Email
                </Button>
              </Form.Item>
            </Form>
          </CardContent>
        </Card>
      </Box>
    </div>
  )
}

export default DirectEmail;