import * as React from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Delete } from '@mui/icons-material';
import { deleteMany } from '../../features/userSlice';
import "./DeleteModal.css"

export default function DeleteModal({open, setOpen, userIds, setSelected, isSelectAll}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    if(!isSelectAll) {
      dispatch( deleteMany({deleteIds: userIds}) );
    } else {
      dispatch( deleteMany({}));
    }
    setSelected([]);
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='delete-Modal'
      >
        <DialogTitle id="alert-dialog-title" style={{display: 'flex', alignItems: 'center'}}>
          <Delete style={{color: 'red'}}/> &nbsp; Are you really going to delete these users?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once deleted, the data permanently lost and can't be restored.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={handleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}